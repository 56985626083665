import React from 'react';
import { useNavigate } from 'react-router-dom';
import Banner1 from '../imgs/ambientes/cadeiras.jpg';
import Banner2 from '../imgs/ambientes/moveis.jpg';
import Banner3 from '../imgs/ambientes/aco.jpg';
import Banner4 from '../imgs/ambientes/home_office.jpg';
import {
  MainContainerMiddle,
  DivImgs,
  BannerText,
  Container,
  MainTitle,
} from '../styles/MiddleBanners';

const MiddleBanners = () => {
  const navigate = useNavigate();

  const handlePressBanner1 = () => {
    navigate('category/Cadeiras');
  };
  const handlePressBanner2 = () => {
    navigate('category/Móveis');
  };
  const handlePressBanner3 = () => {
    navigate('category/Móveis de Aço');
  };
  const handlePressBanner4 = () => {
    navigate('category/Home Office');
  };

  return (
    <Container>
      <MainTitle>DESCUBRA POR <strong>CATEGORIAS</strong></MainTitle>

      <MainContainerMiddle>
        <DivImgs onClick={handlePressBanner1}>
          <img width={'100%'} src={Banner1} alt="Cadeiras" />
          <BannerText>cadeiras</BannerText>
        </DivImgs>
        <DivImgs onClick={handlePressBanner2}>
          <img width={'100%'} src={Banner2} alt="Móveis" />
          <BannerText>móveis</BannerText>
        </DivImgs>
        <DivImgs onClick={handlePressBanner3}>
          <img width={'100%'} src={Banner3} alt="Móveis de Aço" />
          <BannerText>aço</BannerText>
        </DivImgs>
        <DivImgs onClick={handlePressBanner4}>
          <img width={'100%'} src={Banner4} alt="Home Office" />
          <BannerText>home office</BannerText>
        </DivImgs>
      </MainContainerMiddle>
    </Container>
  );
};

export default MiddleBanners;
