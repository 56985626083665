import React from "react";
import IconLixeira from "../imgs/deleteIcon.png";
import { BasketButton } from "../styles/ProductsContainerStyle";

const RemoveFromWishlist = ({ product, onRemove }) => {
  const handleRemoveFromWishlist = () => {
    // Chama a função onRemove passando o ID do produto para removê-lo
    onRemove(product.id);
    alert("Produto removido da sacola!");
  };

  return (
    <BasketButton onClick={handleRemoveFromWishlist}>
      <img src={IconLixeira} alt="Ícone Lixeira" width={32} height={32} />
    </BasketButton>
  );
};

export default RemoveFromWishlist;
