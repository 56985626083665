import React, { useState, useEffect } from 'react';
import styles from '../styles/AmbientHints.module.css';
import { useNavigate } from 'react-router-dom';
import {
  BtnPedirOrc,
  MainProductsContainer,
  ProductItem,
} from '../styles/ProductsContainerStyle';
import { PageTitle, PageTitleContainer } from '../styles/AmbientHintsStyle';
import RemoveFromWishlist from '../components/RemoveFromWishlist';
import { BtnEnvOrc, ButtonContainer } from '../styles/WishListPageStyles';

const WishlistPage = () => {
  const [wishlist, setWishlist] = useState([]);
  const navigate = useNavigate();

  // Recupera a wishlist do localStorage quando o componente é montado
  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(storedWishlist);
  }, []);

  // Função para remover o produto da wishlist
  const handleRemoveFromWishlist = (productId) => {
    const updatedWishlist = wishlist.filter((item) => item.id !== productId);
    setWishlist(updatedWishlist);
    localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
  };

  const handlePedirOrcamentoClick = (product) => {
    navigate(`/product-detail/${product.id}`, { state: { product } });
  };

  const handleSubmitOrcamento = () => {
    if (wishlist.length > 0) {
      const whatsappBaseUrl = 'https://api.whatsapp.com/send';
      const phoneNumber = '554130288100'; // Número para enviar o orçamento
      const initialMessage = 'Olá, gostaria de mais informações sobre os seguintes produtos:%0A';

      const productNamesArray = wishlist.map((product) => product.name);


      const productNames = wishlist
        .map((product) => encodeURIComponent(product.name))
        .join('%0A');

      const whatsappUrl = `${whatsappBaseUrl}?phone=${phoneNumber}&text=${initialMessage}${productNames}`;
      window.open(whatsappUrl, '_blank');

      window.dataLayer.push({
        event: 'interacao_usuario',
        luvizotto_key: 'sacola',
        page_type: window.location.href,
        screen_name: productNamesArray,
        section_name: 'button_sacola',
        element_name: 'adicionar_sacola',
        event_type: 'clique',
        element_type: 'button_adicionar_sacola',
        param_1: '',
      });
    } else {
      alert('Que tal adicionar alguns produtos a sua Sacola?');
    }
  };

  return (
    <div className={styles.container}>
      <PageTitleContainer>
        <PageTitle>Sua Sacola</PageTitle>
      </PageTitleContainer>

      <MainProductsContainer>
        {wishlist.length > 0 ? (
          wishlist.map((product) => (
            <ProductItem key={product.id}>
              <img src={product.image_1} alt={product.name} />
              <p>{product.name}</p>
              <BtnPedirOrc onClick={() => handlePedirOrcamentoClick(product)}>
                Ver detalhes
              </BtnPedirOrc>
              {/* Passando a função handleRemoveFromWishlist para o RemoveFromWishlist */}
              <RemoveFromWishlist product={product} onRemove={handleRemoveFromWishlist} />
            </ProductItem>
          ))
        ) : (
          <p>Sua sacola está vazia.</p>
        )}
      </MainProductsContainer>
      <ButtonContainer>
        <BtnEnvOrc onClick={handleSubmitOrcamento}>Enviar orçamento</BtnEnvOrc>
      </ButtonContainer>
    </div>
  );
};

export default WishlistPage;
