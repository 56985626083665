import React from "react";
import { useParams } from "react-router-dom";
import ProductsContainer from "../components/ProductsContainer";

const Category = () => {
  const { categoryId } = useParams(); // Obtém o categoryId da URL

  return (
    <>
      <ProductsContainer categoryId={categoryId} />
    </>
  );
};

export default Category;
