// src/components/AddToWishlist.js
import React from "react";
import IconSacola from "../imgs/sacola-de-compras.png";
import { BasketButton } from "../styles/ProductsContainerStyle";


const AddToWishlist = ({ product }) => {
  const handleAddToWishlist = () => {
    const existingWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    const isAlreadyInWishlist = existingWishlist.some(
      (item) => item.id === product.id
    );

    if (!isAlreadyInWishlist) {
      const updatedWishlist = [...existingWishlist, product];
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      alert("Produto adicionado à sacola!");
      window.location.href = "/";
    } else {
      alert("Este produto já está na sacola.");
    }

  };

  return (
    <BasketButton onClick={handleAddToWishlist}>
      <img src={IconSacola} alt="Icone Sacola" width={40} height={40} />
    </BasketButton>
  );
};

export default AddToWishlist;
