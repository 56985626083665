import React from "react";
import { Outlet } from "react-router-dom";
import Slider from "../components/Slider";
import AmbientsHints from "../components/AmbientsHints";
import ContactSection from "../components/ContactSection";
import MiddleBanners from "../components/MiddleBanners";
import Carousel from "../components/Carousel";
import productsData from "../data/products.json"; // Caminho correto para o seu JSON
import About from "../components/About";

const Home = () => {
  return (
    <>
      <Slider />
      <div>
        <Outlet />
      </div>
      <ContactSection />
      <MiddleBanners />
      <AmbientsHints />
      <Carousel products={productsData.products} />
      <About/>
    </>
  );
};

export default Home;
