import styled from "styled-components";

export const BtnEnvOrc = styled.div`
  color: #fff;
  background-color: #ff0000;
  padding: 15px;
  border-radius: 15px;
  width: 50%;
  text-align: center; /* Garante que o texto fique centralizado dentro do botão */
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #830000;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;
