import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../styles/AmbientHints.module.css';
import { PageTitle, PageTitleContainer } from '../styles/AmbientHintsStyle';
import productsData from '../data/products.json'; // Importando diretamente
import {
  BtnPedirOrc,
  MainProductsContainer,
  ProductItem, // Importando o novo componente estilizado
} from '../styles/ProductsContainerStyle';
import AddToWishlist from './AddToWishList';

const ProductsContainer = () => {
  const { categoryId, subcategoryId, ambientGuide } = useParams();
  const navigate = useNavigate();

  // Estado para armazenar os produtos
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Carregar os dados do JSON diretamente
  useEffect(() => {
    try {
      setProducts(productsData.products); // Usando o JSON diretamente
      setLoading(false);
    } catch (err) {
      console.error('Erro ao carregar os produtos:', err);
      setError('Não foi possível carregar os produtos.');
      setLoading(false);
    }
  }, []);

  // Filtrando os produtos com base nas categorias, subcategorias e guia de ambiente
  const filteredProducts = products.filter((product) => {
    const matchesCategory = categoryId ? product.category === categoryId : true;
    const matchesSubcategory = subcategoryId
      ? product.subcategory === subcategoryId
      : true;
    const matchesAmbientGuide = ambientGuide
      ? product.ambientGuide === ambientGuide
      : true;

    return matchesCategory && matchesSubcategory && matchesAmbientGuide;
  });

  // Função de navegação para a página de detalhes do produto
  const handlePedirOrcamentoClick = (product) => {
    navigate(`/product-detail/${product.id}`, { state: { product } });
  };

  // Exibindo carregamento, erro ou a lista de produtos
  if (loading) return <p>Carregando produtos...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className={styles.container}>
      <PageTitleContainer>
        <PageTitle>
          <PageTitle>
            {subcategoryId
              ? subcategoryId
              : categoryId
                ? categoryId
                : ambientGuide
                  ? ambientGuide
                  : 'Todos os Produtos'}
          </PageTitle>
        </PageTitle>
      </PageTitleContainer>
      <MainProductsContainer>
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <ProductItem key={product.id}>
              <img
                src={product.image_1}
                alt={product.name}
                onClick={() => handlePedirOrcamentoClick(product)}
              />
              <p style={{ fontSize: 18, marginBottom: 12 }}>{product.name}</p>
              <div className="btn-container">
                <BtnPedirOrc onClick={() => handlePedirOrcamentoClick(product)}>
                  Pedir Orçamento
                </BtnPedirOrc>
              </div>
              <AddToWishlist product={product} />
            </ProductItem>
          ))
        ) : (
          <p>Nenhum produto encontrado para esta categoria.</p>
        )}
      </MainProductsContainer>
    </div>
  );
};

export default ProductsContainer;
