import React from "react";
import styles from "../styles/AmbientHints.module.css";
import ImgContainer from "./ImgContainer";
import { PageTitle, PageTitleContainer } from "../styles/AmbientHintsStyle";

const AmbientsHints = () => {
  // const isMobile = window.innerWidth <= 768;
  return (
    <div className={styles.container}>
      <PageTitleContainer>
        <PageTitle>Guia de Ambiente</PageTitle>
      </PageTitleContainer>
      <ImgContainer />
    </div>
  );
};

export default AmbientsHints;
