import React from "react";
import LogoLuvi from "../imgs/logo.jpg";

// Importar estilos do arquivo separado
import {
  FooterContainer,
  Section,
  Logo,
  Title,
  List,
  ListItem,
  RightsContainer,
} from "../styles/FooterStyles";

// Componente funcional Footer
const Footer = () => {
  return (
    <FooterContainer>
      <Section>
        <Title>Onde Estamos:</Title>
        <List>
          <ListItem>
            <b>Loja 1: </b>RUA DR. FAIVRE, Nº 957 - CENTRO CURITIBA - PARANÁ
          </ListItem>
          <ListItem>
            <b>Loja 2: </b> ROD. JOÃO LEOPOLDO JACOMEL, Nº 11.210 PINHAIS -
            PARANÁ
          </ListItem>
        </List>
      </Section>
      <Section>
        <Title>Contatos</Title>
        <List>
          <ListItem>Telefone Curitiba: (41) 3028-8100</ListItem>
          <ListItem>Telefone Pinhais: (41) 3033-3536</ListItem>
          <ListItem>contato@luvizotto.com.br</ListItem>
        </List>
      </Section>
      <Section>
        <RightsContainer>
          <p>Todos os direitos reservados a Luvizotto</p>
          <Logo src={LogoLuvi} alt="Logo" />
        </RightsContainer>
      </Section>
    </FooterContainer>
  );
};

export default Footer;
