import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    if (window.gtag) return; // Evita carregar o script mais de uma vez

    // Adiciona o script do Google Analytics
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-2VB6NKDSP6";
    script.async = true;
    document.head.appendChild(script);

    // Após o script carregar, inicializa o Google Analytics
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-2VB6NKDSP6');
    };
  }, []);

  return null; // Não renderiza nada, apenas carrega o script do Google Analytics
};

export default GoogleAnalytics;
  