import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
`;

export const Section = styled.div`
  /* flex: 1; */
`;

export const Logo = styled.img`
  max-width: 100px;
  margin-bottom: 10px;
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 5px;
  padding-bottom: 12px;
`;

export const RightsContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;
