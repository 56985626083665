import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import styles from '../styles/Swiper.module.css';
import 'swiper/swiper-bundle.css';


import Slide1 from '../imgs/slides/BannerDesk/1.png';
import Slide2 from '../imgs/slides/BannerDesk/2.png';
import Slide3 from '../imgs/slides/BannerDesk/3.png';
import Slide4 from '../imgs/slides/BannerDesk/4.png';
import Slide5 from '../imgs/slides/BannerDesk/5.png';
import Slide6 from '../imgs/slides/BannerDesk/6.png';
import Slide7 from '../imgs/slides/BannerDesk/7.png';

import Slide1Mobile from '../imgs/slides/BannerMobile/1.png';
import Slide2Mobile from '../imgs/slides/BannerMobile/2.png';
import Slide3Mobile from '../imgs/slides/BannerMobile/3.png';
import Slide4Mobile from '../imgs/slides/BannerMobile/4.png';
import Slide5Mobile from '../imgs/slides/BannerMobile/5.png';
import Slide6Mobile from '../imgs/slides/BannerMobile/6.png';
import Slide7Mobile from '../imgs/slides/BannerMobile/7.png';



const Slider = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const commonSwiperProps = {
    modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    spaceBetween: 30,
    loop: true,
    autoplay: { delay: 6000 },
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  const slides = isMobile
    ? [Slide1Mobile, Slide2Mobile, Slide3Mobile,Slide4Mobile,Slide5Mobile,Slide6Mobile,Slide7Mobile]
    : [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6, Slide7,];

  return (
    <div className={styles.swiper}>
      <Swiper {...commonSwiperProps} slidesPerView={1}>
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <a href="https://api.whatsapp.com/send?phone=554130288100&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20">
              <img
                src={slide}
                alt={`Slide ${index + 1}`}
                style={{ width: '100%', height: isMobile ? '90%' : '100%' }}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
