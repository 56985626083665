import React from 'react';
import { Container, TextOverlay } from '../styles/AboutStyle';

const Timeline = () => {
  return (
    <Container>
    <TextOverlay>
      <h2>Sobre a Luvizotto</h2>
      <p>
      A Luvizotto iniciou seu trabalho quando o Bortholo Hermes Luvizotto trabalhava com vendas e consertos de máquinas de escrever e calcular.
        Há mais de 50 anos, ela conquistou o mercado e hoje conta com uma completa coleção que acompanha as últimas tendências em design, ergonomia e alta qualidade!
        Hoje a Luvizotto Móveis e Papelaria conta com a mais completa e diversificada coleção de móveis para escritório, produzida por grandes fabricantes do mercado nacional, que acompanham as últimas tendências mundiais em design, cores, acabamentos, ergonomia e qualidade!
      </p>
    </TextOverlay>
  </Container>
  );
};

export default Timeline;
