import styled from "styled-components";

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden; // Garante que os produtos fora do limite não apareçam
`;

export const CarouselInner = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
`;

export const Slide = styled.div`
  flex: 0 0 ${(props) => 100 / props.itemsToShow}%; // Define a largura do slide com base no número de itens
  box-sizing: border-box; // Garante que o padding não afete a largura
  padding: 50px; // Espaçamento entre os produtos
  display: flex; // Permite que o conteúdo dentro do slide seja alinhado
  flex-direction: column; // Alinha o conteúdo verticalmente
  align-items: center; // Centraliza o conteúdo do slide
  img {
    transition: transform 0.3s ease; 
  }

  &:hover img {
    transform: scale(1.1); 
  }
`;

export const Image = styled.img`
  width: 100%; // Ajusta a largura da imagem para preencher o slide
  height: 200px; // Define uma altura fixa
  cursor: pointer;
  object-fit: contain; // Mantém a proporção da imagem, cortando o que excede
  margin-bottom: 10px; // Espaçamento abaixo da imagem
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  ${(props) => (props.left ? "left: 10px;" : "right: 10px;")}
`;
