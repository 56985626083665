import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchContainer, Input, ResultItem, ResultsBox, ResultItemLi } from '../styles/SearchBarStyles';

const SearchBar = ({ products }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false); // Estado para saber se o campo está focado
  const resultsRef = useRef(null);  // Referência para a caixa de resultados
  const navigate = useNavigate();

  const handleSearch = (e) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);

    if (searchQuery.trim() === '') {
      setResults([]);
      setIsResultsVisible(false);
      return;
    }

    const regex = new RegExp(`\\b${searchQuery.toLowerCase()}`, 'i');

    const filteredResults = products.filter((product) =>
      regex.test(product.name.toLowerCase())
    );
    
  
    setResults(filteredResults);
    setIsResultsVisible(filteredResults.length > 0);  // Exibe os resultados se houver algum
  };

  const handleResultClick = (product) => {
    navigate(`/product-detail/${product.id}`, {
      state: { product }
    });
  };

  // Adicionando um event listener para fechar a caixa de resultados quando clicar fora dela
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setIsResultsVisible(false);  // Esconde a caixa de resultados
      }
    };

    document.addEventListener('mousedown', handleClickOutside);  // Escuta cliques fora

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);  // Limpeza ao desmontar
    };
  }, []);

  const handleFocus = () => {
    setIsFocused(true); // Define que a barra de pesquisa está focada
    if (query.trim() !== '') {
      setIsResultsVisible(true);  // Mostra os resultados caso tenha texto na barra de pesquisa
    }
  };

  const handleBlur = () => {
    if (query.trim() === '') {
      setIsResultsVisible(false); // Esconde os resultados se a barra estiver vazia
    }
    setIsFocused(false); // Remove o foco da barra de pesquisa
  };

  return (
    <SearchContainer>
      <Input
        type="text"
        placeholder="Buscar produtos..."
        value={query}
        onChange={handleSearch}
        onFocus={handleFocus}  // Quando o campo é focado
        onBlur={handleBlur}    // Quando o campo perde o foco
      />
      {isResultsVisible && results.length > 0 && (
        <ResultsBox ref={resultsRef}>  {/* Associa a referência ao ResultsBox */}
          {results.map((result) => (
            <ResultItem key={result.id}>
              <img src={result.image_1} width="50px" alt="" />
              <ResultItemLi onClick={() => handleResultClick(result)}>
                {result.name}
              </ResultItemLi>
            </ResultItem>
          ))}
        </ResultsBox>
      )}
    </SearchContainer>
  );
};

export default SearchBar;
