import React from "react";
import styles from "../styles/ContactSection.module.css";
import WhatsIcon from "../imgs/whatsapp.png";
import EmailIcon from "../imgs/o-email.png";
import AssistTec from "../imgs/suporte-tecnico.png";

const ContactSection = () => {
  const isMobile = window.innerWidth <= 768;
  return (
    <ul className={`${isMobile ? styles.mobile : styles.container}`}>
      <li className={`${isMobile ? styles.LiMobile : styles.li}`}>
        <div>
          <img src={EmailIcon} alt="Whats App Icon" width={24} height={24} />
          <h3>Solicite seu orçamento</h3>
        </div>
        <span>contato@luvizotto.com.br</span>
      </li>

      <li className={`${isMobile ? styles.LiMobile : styles.li}`}>
        <div>
          <img src={WhatsIcon} alt="Whats App Icon" width={24} height={24} />
          <h3>Compre sem sair de casa</h3>
        </div>
        <a
          className={styles.contactLink}
          href="https://api.whatsapp.com/send/?phone=554130288100&text=Ol%C3%A1%2C+tudo+bem%3F&type=phone_number&app_absent=0"
        >
          <span>(41) 3028-8100</span>
        </a>
      </li>
      {/* Renderiza a linha vertical apenas se não for mobile */}
      <li className={`${isMobile ? styles.LiMobile : styles.li}`}>
        <div>
          <img src={AssistTec} alt="Whats App Icon" width={24} height={24} />
          <h3>Assistência Técnica</h3>
        </div>
        <a
          className={styles.contactLink}
          href="https://api.whatsapp.com/send?phone=5541992890712"
        >
          <span>(41) 99289-0712</span>
        </a>
      </li>
    </ul>

  );
};

export default ContactSection;
