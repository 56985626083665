import styled from 'styled-components';

export const MainProductsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const BtnPedirOrc = styled.div`
  color: #fff;
  background-color: #ff0000;
  padding: 15px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #830000;
  }
`;
export const BasketButton = styled.button`
    position: absolute;
    left: 190px;
    top: 30px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 20px;
  img {
    transition: transform 0.3s ease; 
  }
  &:hover img {
    transform: scale(1.1); 
  }
  
 
`;

export const ProductItem = styled.div`
  width: 200px;
  height: 300px; /* Altura fixa para manter todos os itens do mesmo tamanho */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 80px 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  background-color: #fff;
  text-align: center;
  position: relative;
  cursor: pointer;

  img {
    height: auto; /* Ajusta a altura da imagem para manter a proporção */
    max-height: 150px; /* Altura máxima da imagem */
    object-fit: cover; /* Ajusta a imagem para cobrir o container sem distorcer */
  }

  p {
    margin: 5px 0;
  }

  // Contêiner para o botão, garantindo que ele fique na parte inferior
  .btn-container {
    position: absolute;
    bottom: 24px; /* Espaço do fundo do item */
    width: 100%;
    display: flex;
    justify-content: center; /* Alinha o botão ao centro */
    
  }
`;
