import styled from "styled-components";
import ImageAbout from "../imgs/imagemMin.jpg"


// Estilizando o container principal com a imagem de fundo
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 55vh;
  background-image: url(${ImageAbout}); 
  background-size: cover;
  background-position: center;
  padding: 0 20px;
  position: relative;

  @media (max-width: 768px) {
    height: 75vh; 
  }
`;

// A div com fundo preto e opacidade reduzida
export const TextOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.7); 
  padding: 20px;
  max-width: 600px;
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-family: Arial, sans-serif;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }
`;