import styled from 'styled-components';

export const PageTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: xx-large;
  color: #3c3c3c;
`;
export const PageTitleContainer = styled.div`
  padding: 1px;
  margin-top: 40px;
  margin-bottom: 20px;
  border-radius: 15px;
  width: 100%;
`;
