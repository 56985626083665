import styled from 'styled-components';

export const MainProductContainer = styled.main`
  padding: 60px;
  margin: 30px;
`;
export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  border: solid #cacaca 1px;
  margin: 50px auto; /* Centraliza sem ultrapassar */
  max-width: calc(100% - 100px); /* Subtrai as margens */
  padding: 20px;
`;

export const DescriptionTitle = styled.p`
  color: red;
  font-weight: 600;
  font-size: 20px;
  border-bottom: solid #767676 1px;
`;

export const SecondaryPrdContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`;
export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 50%;
`;

export const ButtonBudget = styled.div`
  background-color: #ff0000;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px;
  &:hover {
    background-color: #520000;
  }
`;

export const ButtonAddToCart = styled.button`
  background-color: #353535;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px;
  &:hover {
    background-color: #000000;
  }
`;
