import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import productsData from "../data/products.json"; // ajuste o caminho conforme necessário
import {
  CarouselContainer,
  CarouselInner,
  Slide,
  Button,
  Image,
} from "../styles/CarouselStyles"; // ajuste o caminho conforme necessário

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const products = productsData.products.filter(
    (product) => product.isMostSeller
  ); // filtrar os mais vendidos

  const isMobile = window.innerWidth <= 768;
  const itemsToShow = isMobile ? 1 : 3; // 1 se for mobile, 3 caso contrário

  const navigate = useNavigate(); // hook para navegação

  const nextSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % Math.ceil(products.length / itemsToShow)
    );
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + Math.ceil(products.length / itemsToShow)) %
        Math.ceil(products.length / itemsToShow)
    );
  };

  const handleProductClick = (product) => {
    navigate(`/product-detail/${product.id}`, { state: { product } }); // navega para a página de detalhes do produto
  };

  return (
    <CarouselContainer>
      <Button left onClick={prevSlide}>
        &#10094;
      </Button>
      <CarouselInner
        style={{
          transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)`,
        }}
      >
        {products.map((product) => (
          <Slide
            key={product.id}
            itemsToShow={itemsToShow}
            onClick={() => handleProductClick(product)}
          >
            <Image src={product.image_1} alt={product.name} />
            <div
              style={{ padding: 10, cursor: "pointer", textAlign: "center" }}
            >
              <h2 style={{ fontSize: 16 }}>{product.name}</h2>
              <p>Confira</p>
            </div>
            
          </Slide>
        ))}
      </CarouselInner>
      <Button onClick={nextSlide}>&#10095;</Button>
    </CarouselContainer>
  );
};

export default Carousel;
