import React from 'react';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/swiper-bundle.css';
import {
  ButtonAddToCart,
  ButtonBudget,
  DescriptionContainer,
  DescriptionTitle,
  MainProductContainer,
  ProductDetails,
  SecondaryPrdContainer,
} from '../styles/ProductDetail';
import DOMPurify from 'dompurify';

import ContactSection from '../components/ContactSection';

const ProductDetail = () => {
  const location = useLocation();
  const { product } = location.state || {};

  const sanitizedDescription = DOMPurify.sanitize(product?.description || '');

  const handleBudgetButton = () => {
    const url = `https://api.whatsapp.com/send?phone=554130288100&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20-%20${product?.name}`;
    window.location.href = url;

    window.dataLayer.push({
      event: 'interacao_usuario',
      luvizotto_key: 'orcamento',
      page_type: window.location.href,
      screen_name: product.name,
      section_name: 'button_orcamento',
      element_name: 'solicitar_orcamento',
      event_type: 'clique',
      element_type: 'button_solicitar_orcamento',
      param_1: product.name,
    });
  };

  const handleAddToWishlist = () => {
    const existingWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    const isAlreadyInWishlist = existingWishlist.some(
      (item) => item.id === product.id
    );

    if (!isAlreadyInWishlist) {
      const updatedWishlist = [...existingWishlist, product];
      localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
      alert('Produto adicionado à sacola!');
      window.location.href = '/';
    } else {
      alert('Este produto já está na sacola.');
    }
    window.dataLayer.push({
      event: 'interacao_usuario',
      luvizotto_key: 'sacola',
      page_type: window.location.href,
      screen_name: product.name,
      section_name: 'button_sacola',
      element_name: 'adicionar_sacola',
      event_type: 'clique',
      element_type: 'button_adicionar_sacola',
      param_1: product.name,
    });
  };

  // Filtra as imagens válidas antes de renderizar
  const validImages = [
    product.image_1,
    product.image_2,
    product.image_3,
    product.image_4,
  ].filter((img) => img && img !== ''); // Remove imagens vazias ou nulas

  return (
    <>
      <MainProductContainer>
        {product ? (
          <SecondaryPrdContainer>
            <div style={{ width: 400, textAlign: 'center' }}>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={20}
                slidesPerView={1}
                loop={true}
                navigation
                pagination={{ clickable: true }}
                autoplay={false}
              >
                {/* Verifica se há imagens válidas e renderiza apenas essas */}
                {validImages.length > 0 ? (
                  validImages.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={product.name} width={300} />
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide>
                    <p>Imagem não disponível</p>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>

            <ProductDetails>
              <h2>{product.name}</h2>
              <p>SKU: {product.sku}</p>
              {product.price > 0 && <p>Price: {product.price}</p>}
              <ButtonBudget onClick={handleBudgetButton}>
                Solicitar Orçamento
              </ButtonBudget>
              <ButtonAddToCart onClick={handleAddToWishlist}>
                Adicionar à Sacola
              </ButtonAddToCart>
            </ProductDetails>
          </SecondaryPrdContainer>
        ) : (
          <p>Product not found.</p>
        )}
      </MainProductContainer>
      <ContactSection />
      {product && (
        <DescriptionContainer>
          <DescriptionTitle>Descrição do Produto</DescriptionTitle>
          <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
        </DescriptionContainer>
      )}
    </>
  );
};

export default ProductDetail;
