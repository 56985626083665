import styled from "styled-components";

export const MainContainerMiddle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f3f3;
  padding: 30px;
  flex-wrap: wrap;
`;
export const Container = styled.div`
  text-align: center;
  margin-top: 80px;
`;
export const MainTitle = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: x-large;
  font-weight: 350;
`;


export const DivImgs = styled.div`
  cursor: pointer;
  position: relative;
  margin: 20px;
  overflow: hidden; 

  img {
    transition: transform 0.3s ease; 
  }

  &:hover img {
    transform: scale(1.1); 
  }
`;

export const BannerText = styled.p`
  position: absolute;
  font-size: 20px;
  bottom: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  margin: 0; // Remove a margem padrão do parágrafo
`;
