import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ImgOverlay,
  ImgNameContainer,
  ImgName,
  BtnShowMore,
  ImagesContainer,
  Container,
  MainContainer,
} from "../styles/ImgContainerStyle";

const images = [
  { id: 1, src: "diretiva.jpg", name: "Diretiva", ambientGuide: "Diretiva" },
  { id: 2, src: "Executiva.jpg", name: "Executiva", ambientGuide: "Executiva" },
  { id: 3, src: "Gerencia.jpg", name: "Gerência", ambientGuide: "Gerência" },
  { id: 4, src: "homeoffice.jpg", name: "Home Office", ambientGuide: "Home Office" },
  { id: 5, src: "presidente.jpg", name: "Presidente", ambientGuide: "Presidente" },
  { id: 6, src: "diretorexec.jpg", name: "Diretor & Executiva", ambientGuide: "Diretor & Executiva" },
];

const ImgContainer = () => {
  const [hoveredImage, setHoveredImage] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = (id) => {
    setHoveredImage(id);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const handlePressShowMore = () => {
    setShowMore(!showMore);
  };

  const handleNavigate = (ambientGuide) => {
    navigate(`/ambient/${ambientGuide}`); // Redireciona para a página do ambiente
  };

  return (
    <MainContainer>
      <Container>
        {images.slice(0, showMore ? images.length : 3).map((image) => (
          <ImagesContainer
            key={image.id}
            onMouseEnter={() => handleMouseEnter(image.id)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleNavigate(image.ambientGuide)}
            style={{
              position: "relative",
              display: "inline-block",
              margin: "30px",
              cursor: "pointer",
            }}
          >
            {hoveredImage === image.id ? (
              <img
                src={require(`../imgs/ambientes/${image.src}`)}
                alt={image.name}
                style={{ width: "100%", borderRadius: 40 }}
              />
            ) : (
              <img
                src={require(`../imgs/ambientes/${image.src}`)}
                alt={image.name}
                style={{ width: "100%", borderRadius: 20 }}
              />
            )}

            {hoveredImage === image.id && <ImgOverlay />}

            <ImgNameContainer>
              <ImgName>{image.name}</ImgName>
            </ImgNameContainer>
          </ImagesContainer>
        ))}
      </Container>
      {images.length > 3 && (
        <BtnShowMore onClick={handlePressShowMore}>
          {showMore ? "ver menos" : "ver mais"}
        </BtnShowMore>
      )}
    </MainContainer>
  );
};

export default ImgContainer;
