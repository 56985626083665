import { useEffect } from "react";
import TagManager from "react-gtm-module";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import ProductDetail from './pages/ProductDetail';
import Footer from './components/Footer';
import '../src/styles/global.css';
import Category from "./pages/Category";
import Header from "./components/Header";
import WishlistPage from "./pages/WishListPage";
import ProductsContainer from "./components/ProductsContainer";
import GoogleAnalytics from "./components/GoogleAnalytics"; // Google Analytics já configurado

function App() {
    useEffect(() => {
        const tagManagerArgs = {
            gtmId: 'GTM-TJQS35L', 
        };
        TagManager.initialize(tagManagerArgs); 
    }, []);

    return (
        <BrowserRouter>
            <GoogleAnalytics />
            <Header />
            <main>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="category/:categoryId" element={<Category />} />
                    <Route path="category/:categoryId/:subcategoryId" element={<Category />} />
                    <Route path="/ambient/:ambientGuide" element={<ProductsContainer />} />
                    <Route path="product-detail/:productId" element={<ProductDetail />} />
                    <Route path="wishlist" element={<WishlistPage />} />
                </Routes>
            </main>
            <Footer />
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
