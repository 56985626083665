import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Header.module.css';
import Logo from '../imgs/logo.jpg';
import IconAbrir from '../imgs/menu.png';
import IconFechar from '../imgs/marca-cruzada.png';
import IconMais from '../imgs/icon-mais.png';
import IconMenos from '../imgs/icon-menos.png';
import IconSacola from '../imgs/sacola-de-compras.png';
import { useEffect } from 'react';
import data from "../data/products.json";
import SearchBar from './SearchBar';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // eslint-disable-next-line
  const [activeItem, setActiveItem] = useState(null);
  const [wishlistCount, setWishlistCount] = useState(0);

  // Estado para controlar os submenus
  const [subMenuOpen, setSubMenuOpen] = useState({
    moveis: false,
    cadeiras: false,
    escritorio: false,
  });
  const isMobile = window.innerWidth <= 768;

  const handleMouseEnter = (item) => {
    if (!isMobile) {
      setActiveItem(item);
      setSubMenuOpen((prevState) => ({
        ...prevState,
        [item]: true,
      }));
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setActiveItem(null);
      setSubMenuOpen({
        moveis: false,
        cadeiras: false,
        escritorio: false,
      });
    }
  };

  useEffect(() => {
    const wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlistCount(wishlist.length);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const handlePressShowMore = (item) => {
    setSubMenuOpen((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  return (
    <header className={styles.header}>
      <a href="/">
        <img src={Logo} width={160} height={58} alt="Logo" />
      </a>
      {!isMobile && <SearchBar products={data.products}/>}
      <button className={styles.hamburger} onClick={toggleMenu}>
        {isMenuOpen ? (
          <img src={IconFechar} width={30} height={25} alt="Fechar menu" />
          ) : (
            <img src={IconAbrir} width={30} height={25} alt="Abrir menu" />
            )}
      </button>
      <nav className={`${styles.nav} ${isMenuOpen ? styles.navOpen : ''}`}>
        <img
          onClick={closeMenu}
          src={IconFechar}
          className={styles.buttonFechar}
          width={20}
          height={20}
          alt="Fechar menu"
        />
        <ul className={styles.navList}>
            {isMobile && <SearchBar products={data.products}/>}
          <li
            className={styles.navItem}
            onMouseEnter={() => handleMouseEnter('moveis')}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.menuHamburguerItemContainer}>
              <div className={styles.iconContainer}>
                <img
                  onClick={() => handlePressShowMore('moveis')}
                  src={subMenuOpen.moveis ? IconMenos : IconMais}
                  className={styles.buttonFechar}
                  width={25}
                  height={25}
                  alt="Fechar menu"
                />
              </div>
              <Link to="/category/Móveis" onClick={closeMenu}>
                móveis
              </Link>
            </div>
            {subMenuOpen.moveis && (
              <ul className={styles.subMenu}>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Móveis/Aparadores" onClick={closeMenu}>
                    aparadores
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Móveis/Armários" onClick={closeMenu}>
                    armários
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Móveis/Balcões" onClick={closeMenu}>
                    balcões
                  </Link>
                </li>

                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Móveis/Mesas" onClick={closeMenu}>
                    mesas
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Móveis/Estação de Trabalho"
                    onClick={closeMenu}
                  >
                    estação de trabalho
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Móveis/Recepção" onClick={closeMenu}>
                    recepção
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Móveis/Call Center" onClick={closeMenu}>
                    call center
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Móveis/Home Office" onClick={closeMenu}>
                    home office
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li
            className={styles.navItem}
            onMouseEnter={() => handleMouseEnter('cadeiras')}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.menuHamburguerItemContainer}>
              <div className={styles.iconContainer}>
                <img
                  onClick={() => handlePressShowMore('cadeiras')}
                  src={IconMais}
                  className={styles.buttonFechar}
                  width={25}
                  height={25}
                  alt="Fechar menu"
                />
              </div>
              <Link to="/category/Cadeiras" onClick={closeMenu}>
                cadeiras e poltronas
              </Link>
            </div>

            {subMenuOpen.cadeiras && (
              <ul className={styles.subMenu}>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Cadeiras/Diretivas" onClick={closeMenu}>
                    diretivas
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Cadeiras/Operativas" onClick={closeMenu}>
                    operativas
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Cadeiras/Coletivas" onClick={closeMenu}>
                    coletivas
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Cadeiras/Gamer" onClick={closeMenu}>
                    gamer
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Cadeiras/Home Office" onClick={closeMenu}>
                    home office
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Cadeiras/Fixa" onClick={closeMenu}>
                    fixa
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Cadeiras/Obesos" onClick={closeMenu}>
                    obesos
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Cadeiras/Longarinas" onClick={closeMenu}>
                    longarias
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Cadeiras/Convenção" onClick={closeMenu}>
                    convenção
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li
            className={styles.navItem}
            onMouseEnter={() => handleMouseEnter('escritorio')}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.menuHamburguerItemContainer}>
              <div className={styles.iconContainer}>
                <img
                  onClick={() => handlePressShowMore('escritorio')}
                  src={IconMais}
                  className={styles.buttonFechar}
                  width={25}
                  height={25}
                  alt="Fechar menu"
                />
              </div>
              <Link to="/category/Escritório e Papelaria" onClick={closeMenu}>
                escritório e papelaria
              </Link>
            </div>
            {subMenuOpen.escritorio && (
              <ul className={styles.subMenu}>
                <li className={styles.navItemSubMenu}>
                  <Link to="/category/Escritório e Papelaria/Agendas" onClick={closeMenu}>
                    agendas
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Artigos de Papelaria"
                    onClick={closeMenu}
                  >
                    artigos de papelaria
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Canetas"
                    onClick={closeMenu}
                  >
                    canetas
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Lápis"
                    onClick={closeMenu}
                  >
                    lápis
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Lapiseiras"
                    onClick={closeMenu}
                  >
                    lapiseiras
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Fita Adesiva"
                    onClick={closeMenu}
                  >
                    fita adesiva
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Papel Sulfite"
                    onClick={closeMenu}
                  >
                    papel sulfite
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Saco Plástico"
                    onClick={closeMenu}
                  >
                    saco plástico
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Sacolas"
                    onClick={closeMenu}
                  >
                    sacolas
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Arquivo Morto"
                    onClick={closeMenu}
                  >
                    arquivo morto
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Encadernação & Plastificação"
                    onClick={closeMenu}
                  >
                    encadernação & plastificação
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Higiene & Limpeza"
                    onClick={closeMenu}
                  >
                    higiene & limpeza
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Escritório e Papelaria/Cartuchos & Toner"
                    onClick={closeMenu}
                  >
                    cartucho & toner
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li
            className={styles.navItem}
            onMouseEnter={() => handleMouseEnter('moveis_de_aco')}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.menuHamburguerItemContainer}>
              <div className={styles.iconContainer}>
                <img
                  onClick={() => handlePressShowMore('moveis_de_aco')}
                  src={IconMais}
                  className={styles.buttonFechar}
                  width={25}
                  height={25}
                  alt="Fechar menu"
                />
              </div>
              <Link to="/category/Móveis de Aço" onClick={closeMenu}>
                móveis de aço
              </Link>
            </div>
            {subMenuOpen.moveis_de_aco && (
              <ul className={styles.subMenu}>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Móveis de Aço/Estantes de Aço"
                    onClick={closeMenu}
                  >
                    estantes
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Móveis de Aço/Arquivos de Aço"
                    onClick={closeMenu}
                  >
                    arquivos
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Móveis de Aço/Roupeiros de Aço"
                    onClick={closeMenu}
                  >
                    roupeiros
                  </Link>
                </li>

                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Móveis de Aço/Armários de Aço"
                    onClick={closeMenu}
                  >
                    armários
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Móveis de Aço/Rack Servidor"
                    onClick={closeMenu}
                  >
                    rack servidor
                  </Link>
                </li>
                <li className={styles.navItemSubMenu}>
                  <Link
                    to="/category/Móveis de Aço/Lockers"
                    onClick={closeMenu}
                  >
                    lockers
                  </Link>
                </li>
              </ul>
            )}
          </li>
          {/* <li className={styles.navItem}>
            <div className={styles.contato}>
              <Link to="/contato" onClick={closeMenu}>
                contato
              </Link>
            </div>
          </li> */}
          <li className={styles.navItem}>
            <div className={styles.iconBasket}>
              <Link to="/wishlist" onClick={closeMenu}>
                <img
                  src={IconSacola}
                  width={32}
                  height={32}
                  alt="Icone Sacola"
                />
              </Link>
              {wishlistCount > 0 && (
                <div className={styles.counter}>{wishlistCount}</div>
              )}
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
