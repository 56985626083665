import styled from "styled-components";

export const Container = styled.div``;
export const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ImgOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

export const ImgNameContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`;

export const ImgName = styled.span`
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  transition: font-size 0.3s ease, text-shadow 0.3s ease;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  cursor: pointer;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BtnShowMore = styled.div`
  color: #fff;
  background-color: #000;
  padding: 15px 25px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px; /* Espaço entre o botão e as imagens */
  &:hover {
    background-color: #333;
  }
`;
